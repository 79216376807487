/**
 * Check if the header is scrolled
 */
export default function appHeader() {
    /**
     * Scroll header
     */
    const header = document.querySelector('.site-header');
    // Check in case of page refresh or history back navigation
    checkScrollHeader(header);
    // Check on scroll
    document.addEventListener('scroll', () => checkScrollHeader(header));

    function checkScrollHeader(header) {
        const scrollTop = window.scrollY;
        if (scrollTop > 0) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    }

    /**
     * Site menu toggle
     */
    const hamburgerBtns = document.querySelectorAll('.menu-toggler');
    const menu = document.querySelector('.site-menu');
    for (const element of hamburgerBtns) {
        element.addEventListener('click', () => {
            menu.classList.toggle('open');
            document.body.classList.toggle('menu-open');
        });
    }
}
