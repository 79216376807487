import "bootstrap/js/dist/collapse"; // Collapse: for navbar mobile collapse and accordion
import "bootstrap/js/dist/dropdown"; // Dropdown: for navbar submenus
import bodyLoaded from "./partials/bodyLoaded";
import appHeader from "./partials/appHeader";
import siteSliders from "./partials/sliders";
import modal from "./partials/modal";
import youtubeVideo from "./partials/youtubeVideo";
import scrollAnimations from "./partials/scrollAnimations"; // <-- requires GSAP (npm i gsap)

/**
 * MAIN APP JS LOGIC
 */

// CHECK IF DOCUMENT IS READY
if (document.readyState !== "loading") {
  docIsReady();
} else {
  document.addEventListener("DOMContentLoaded", docIsReady);
}

// DOCUMENT READY FUNCTION
function docIsReady() {
  /**
   * Remove body onload layer
   */
  bodyLoaded();

  /**************************************************************************************
        CUSTOM CODE
     **************************************************************************************/

  /**
   * Check if the header is scrolled
   */
  appHeader();

  /**
   * Site sliders
   */
  siteSliders();

  /**
   * Modal
   */
  modal();

  /**
   * Youtube Video
   */
  youtubeVideo();

  /**
   * Site scroll animations
   */
  scrollAnimations();
} // End Document Ready
