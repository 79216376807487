/**
 * Site modal
 */
export default function modal() {
  const modalBtns = document.querySelectorAll(".box-modal-btn");
  if (!modalBtns) return;

  modalBtns.forEach((btn) => {
    const modal = btn.nextElementSibling;
    const closeBtn = modal.querySelector(".close");

    btn.addEventListener("click", function (e) {
      e.preventDefault();
      modal.showModal();
      document.body.classList.add("modal-open");
    });

    closeBtn.addEventListener("click", function () {
      modal.close();
      document.body.classList.remove("modal-open");
    });

    // Close modal when clicking on the backdrop
    modal.addEventListener("click", function (event) {
      const rect = modal.getBoundingClientRect();
      const isInModal = rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
      if (!isInModal) {
        modal.close();
        document.body.classList.remove("modal-open");
      }
    });
  });
}
