import Swiper from 'swiper';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';

export default function siteSliders() {
    /**
     * Timeline Carousel
     */
    // const timelineText = document.querySelector('.site-timeline .col-text');
    // const timelineCarousel = document.querySelector('.wrap-timeline-carousel');

    // Dates
    const timelineDates = new Swiper('.timeline-carousel-dates', {
        modules: [FreeMode], // Modules
        spaceBetween: 40,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            768: {
                slidesPerView: 5,
            },
        },
    });

    // Content
    new Swiper('.timeline-carousel', {
        modules: [Thumbs, Navigation], // Modules
        speed: 1000,
        thumbs: {
            swiper: timelineDates,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}
