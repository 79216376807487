import videojs from "video.js";
import "videojs-youtube";

const youtubeVideo = () => {
  const videoSections = document.querySelectorAll(".video__section");
  if (videoSections.length > 0) {
    videoSections.forEach((videoSection) => {  
        const videoElement = videoSection.querySelector(".video-js");
        const videoPoster = videoSection.querySelector(".video-poster");

        if (videoElement) {
          const videoId = "#" + videoElement.id;
          const player = videojs(videoId, {
            techOrder: ["youtube", "html5"],
            sources: [{
                type: "video/youtube",
                src: "https://www.youtube.com/embed/" + videoId
            }],
            autoplay: false,
            controls: false,
            width: "100%",
            preload: "none",
          });

          const linkvideo = videoSection.querySelector(".linkvideo");
          //   const linkvolume = videoSection.querySelector(".linkvolume");

          const showLinks = () => {
            linkvideo.style.opacity = 1;
            // linkvolume.style.opacity = 1;
          };

          const hideLinks = () => {
            linkvideo.style.opacity = 0;
            // linkvolume.style.opacity = 0;
          };

          // Play/Pause Video Btn
          linkvideo.addEventListener("click", () => {
            linkvideo.classList.toggle("play");
            if (linkvideo.classList.contains("play")) {
              const videoJsElement = videoSection.querySelector(".video-js");
              videoJsElement.style.display = "block";
              //   linkvolume.style.display = "block";
              hideLinks();
              if (videoPoster) {
                videoPoster.style.display = "none";
              }
              player.play();

              videoSection.addEventListener("mouseenter", showLinks);
              videoSection.addEventListener("touchstart", showLinks);
              videoSection.addEventListener("mouseleave", hideLinks);
              videoSection.addEventListener("touchend", hideLinks);
            } else {
              showLinks();
              player.pause();
            }

            player.on("ended", () => {
              const videoJsElement = videoSection.querySelector(".video-js");
              if (videoPoster) {
                videoPoster.style.display = "none";
              }
            });
          });

          // Mute/Unmute Video
          //   linkvolume.addEventListener("click", () => {
          //     linkvolume.classList.toggle("play");
          //     if (linkvolume.classList.contains("play")) {
          //       player.muted(true);
          //     } else {
          //       player.muted(false);
          //     }
          //   });

          // Play/Stop Video as Modal open/close
          const siteModal = videoSection.parentElement.parentElement;
          if (siteModal) {
            siteModal.addEventListener("open", () => {
              player.play();
              if (videoPoster) {
                videoPoster.style.display = "none";
              }
            });
            siteModal.addEventListener("close", () => {
              player.pause();
            });
          }
        }
      
    });
  }
};

export default youtubeVideo;
